<script setup lang="ts">
import {
  inject, onBeforeMount, onMounted, type Ref, ref,
} from 'vue';
import {
  CdrButton, CdrContainer, CdrText,
} from '@rei/cedar';

import HcHomeComponent from './components/HcHomeComponent.vue';
import CategoriesComponent from './internalPages/CategoriesBeta.vue';
import CardLinksComponent from './components/CardLinksComponent.vue';
import GladlySearch from '../../components/gladlySearchBar/GladlySearch.vue';
import ContactUsSingle from './internalPages/ContactUsSingle.vue';
// BELONGS TO ANOTHER PAGE
import CUContentComponent from '../../components/contactUs/CUContentComponent.vue';

import { type IHelpCenter, IHelpCenterKeys } from './models/interfaces/HelpComponent';
import { useUserAccountData } from '../../common/composables/useUserAccountData';
import { isPageOfType, scrollToTop } from '../../common/utils';
import { sendClickAnalytics } from '../../utils';
import tags from '../../utils/globalTags';

const store: any = inject('store');
const props: IHelpCenter = store.getValues(IHelpCenterKeys);

onBeforeMount(() => scrollToTop());

const showContactSection: Ref<boolean> = ref(false);
const userInfo: Ref<any> = ref();
let showContactUsSectionOnPage = true;
const checkIfArticlePageDisplayed: Ref<boolean> = ref(false);
const isContactStandalone: Ref<boolean> = ref(false);
const isLanding: Ref<boolean> = ref(true);

onMounted(async () => {
  const { helpCenterName } = props;
  if (window && typeof window !== 'undefined') {
    const url = new URL(window.location.href);
    const path = url.searchParams.get('article');
    isLanding.value = !!path;
    isContactStandalone.value = path === 'contact-rei';
  }
  if (!helpCenterName) {
    userInfo.value = await useUserAccountData().then((res) => res);
    showContactUsSectionOnPage = props.showContactUsOnPage?.showOnHelpCenterPage;
  }
});

const redirectToURL = (url: string | Location) => {
  const clickData = {
    pageName: '',
    linkName: tags.HELP_CLICK_CONTACT_US,
  };
  sendClickAnalytics(clickData);
  window.location = url as Location;
};

const contactUsClick = () => {
  const clickData = {
    pageName: '',
    linkName: tags.HELP_CLICK_SHOW_CONTACT_US,
  };
  sendClickAnalytics(clickData);
  if (props.showContactUsPage) {
    redirectToURL('/help/contact');
    showContactSection.value = false;
  } else {
    showContactSection.value = true;
  }
  checkIfArticlePageDisplayed.value = window !== undefined && isPageOfType('a');
};
</script>

<template>
  <div>
    <div
      v-if="isLanding"
      id="help-center-new"
      class="help-center-new"
    >
      <ContactUsSingle
        v-if="isContactStandalone"
      />
    </div>
    <div
      v-show="!isLanding"
      id="help-center-new"
      :class="!props.helpCenterName && 'help-center-new'"
    >
      <div
        v-if="!props.helpCenterName"
        id="help-center-header"
        class="help-center-header"
        style="display: none"
      >
        <CdrText
          tag="h1"
          class="help-center-main-heading"
          tabindex="0"
          data-ui="coop-help-center"
        >
          Have a question?
        </CdrText>
      </div>
      <GladlySearch
        v-if="!props.helpCenterName && !isContactStandalone"
      />
      <CardLinksComponent
        v-if="props.showSectionCardLinks && !props.helpCenterName"
      />
      <HcHomeComponent
        v-if="!props.helpCenterName"
      />
      <CategoriesComponent
        v-if="!!props.helpCenterName"
      />
    </div>
    <CdrContainer
      v-if="showContactUsSectionOnPage"
      class="help-center"
    >
      <div v-if="!props.showContactUsAccordion && !isLanding">
        <div
          v-if="!showContactSection"
          class="help-center-contact-us"
        >
          <CdrText
            tag="h2"
            class="help-center__accordion-header"
            data-ui="still-need-more-help"
            tabindex="0"
          >
            Need more help?
          </CdrText>
          <CdrButton
            modifier="secondary"
            @click="contactUsClick"
          >
            Contact us
          </CdrButton>
        </div>
        <CUContentComponent
          v-else
          :id="(props.selectedCategory || checkIfArticlePageDisplayed) ? 'contact-us-home-class': 'contact-us-hc-home-class'"
          :retail-store-hours-events="props.retailStoreHoursEvents"
          :email-response-time="props.emailResponseTime"
          :help-center-numbers="props.helpCenterNumbers"
          :class-name="(props.selectedCategory || checkIfArticlePageDisplayed || isLanding) ? 'contact-us-home-class': 'contact-us-hc-home-class'"
        />
      </div>
    </CdrContainer>
  </div>
</template>

<style lang="scss">
  @import 'src/main/style/helpCenter.scss';
</style>
