import type { CallCenterModel } from "../../../../api/interfaces/callCenterModel";

export type IHCHomeComponent = Pick<
  CallCenterModel,
  | 'gladlyNewHelpCenters'
  | 'helpCenterNames'
>;

export const IHCHomeComponentKeys: (keyof IHCHomeComponent)[] = [
  'gladlyNewHelpCenters',
  'helpCenterNames',
]