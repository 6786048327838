import type { CallCenterModel } from "../../../../api/interfaces/callCenterModel";

export type IHelpCenter = Pick<
  CallCenterModel,
  | 'retailStoreHoursEvents'
  | 'emailResponseTime'
  | 'helpCenterName'
  | 'selectedCategory'
  | 'showContactUsPage'
  | 'showContactUsAccordion'
  | 'helpCenterNumbers'
  | 'showContactUsOnPage'
  | 'showSectionCardLinks'
  | 'showQuickToolsOnPage'
  | 'showOrderDetails'
  | 'popularTopicsLinks'
>;

export const IHelpCenterKeys: (keyof IHelpCenter)[] = [
  'retailStoreHoursEvents',
  'emailResponseTime',
  'helpCenterName',
  'selectedCategory',
  'showContactUsPage',
  'showContactUsAccordion',
  'helpCenterNumbers',
  'showContactUsOnPage',
  'showSectionCardLinks',
  'showQuickToolsOnPage',
  'showOrderDetails',
  'popularTopicsLinks',
];
