<script setup lang="ts">
import { inject } from 'vue';
import contactUs from '../components/contactUs.vue';
import { ContactUsSingle, ContactUsSingleKeys } from '../models/interfaces/ConstactUsSingle';

const store: any = inject('store');
const props: ContactUsSingle = store.getValues(ContactUsSingleKeys);

</script>
<template>
  <section
    class="article-container"
    tabindex="0"
  >
    <contactUs
      :retail-store-hours-events="props.retailStoreHoursEvents"
      :email-response-time="props.emailResponseTime"
      :help-center-numbers="props.helpCenterNumbers"
      :popular-topics-links="props.popularTopicsLinks"
    />
  </section>
</template>
<style lang="scss">
  @import 'src/main/style/pages/internal/article.scss';
</style>
