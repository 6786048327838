<script setup lang="ts">
import {
  defineAsyncComponent, inject, type Ref, ref,
} from 'vue';
import {
  CdrBreadcrumb, CdrText,
} from '@rei/cedar';

import GladlySearch from '../../../components/gladlySearchBar/GladlySearch.vue';
import CategoriesCards from '../components/CategoriesCards.vue';
import CategoryDetails from '../components/CategoryDetails.vue';

import { type ICategoriesComponent, ICategoriesComponentKeys } from '../models/interfaces/CategoriesComponent';
import { camelCase } from '../../../common/utils';

const store: any = inject('store');
const props: ICategoriesComponent = store.getValues(ICategoriesComponentKeys);

const QuickToolsComponent = defineAsyncComponent(() => import('../../../components/quickTools/QuickTools.vue'));
const CatPopularLinksComponent = defineAsyncComponent(() => import('../components/CatPopularLinksComponent.vue'));
const HelpErrorPage = defineAsyncComponent(() => import('../components/HelpErrorPage.vue'));

const selectedCategoryDetails: Ref<{ [key:string]: any } | undefined> = ref({});
let hcSections = props.helpCenterCDNResponse?.sections;
const breadCrumbItems = [{
  item: {
    url: '/help',
    name: 'Help Center',
  },
}];

const findSelectedHelpCenter = (arr: any[] | undefined) => arr && arr.find((el: { helpCenterName: any; }) => camelCase(el.helpCenterName) === props.helpCenterName);
const selectedHelpCenter = findSelectedHelpCenter(props.hasSecondaryHC ? props.gladlyNewHelpCenters : props.helpCenterNames);

const calculateSelectedCategoryDetails = () => {
  if (props.hasSecondaryHC && props.selectedCategory === 'seeAll') {
    const categoryDetails: { [key: string]: any } = {};
    const headerTemplate = `for ${selectedHelpCenter?.helpCenterName}`;
    categoryDetails.header = `All help content ${selectedHelpCenter
      ? headerTemplate : ''}`;
    categoryDetails.id = '';
    categoryDetails.answerIds = hcSections && hcSections.map(((section) => section.answerIds)).flat(1);
    categoryDetails.answers = hcSections && hcSections.map(((section) => section.answers)).flat(1);
    selectedCategoryDetails.value = categoryDetails;
  } else {
    selectedCategoryDetails.value = hcSections && hcSections.find((hc) => {
      if (props.selectedCategory === 'seeAll' && hc.header) {
        return hc.header.includes('All help content');
      }
      return camelCase(hc.header) === props.selectedCategory;
    });
  }
};

(() => {
  if ((hcSections && hcSections.length > 0) && props.selectedCategory) {
    calculateSelectedCategoryDetails();
  }
  if (hcSections && hcSections.length > 0) {
    hcSections = hcSections.filter((obj) => obj.header && !obj.header.includes('All help content'));
    const results: any[] = [];
    while (hcSections.length) {
      results.push(hcSections.splice(0, 3));
    }
    hcSections = results;
  } else {
    hcSections = [];
  }
})();
</script>

<template>
  <div
    id="help-center-category"
    class="help-center-category"
  >
    <div
      id="help-center-category-section-header"
      class="help-center-category-section-header"
    >
      <CdrBreadcrumb
        v-if="(hcSections && hcSections.length > 0)"
        :truncation-enabled="false"
        :items="breadCrumbItems"
        class="help-center-category-bc"
      />
      <CdrText
        tag="h1"
        tabindex="0"
        class="help-center-category-heading"
        data-ui="help-center-category-heading"
      >
        {{ (selectedHelpCenter && selectedHelpCenter.helpCenterName) || '' }}
      </CdrText>
    </div>
    <GladlySearch
      :parent="'Category'"
    />
    <QuickToolsComponent
      v-if="props.showQuickToolsOnPage.showOnCategoryPage && (props.quickTools && props.quickTools.length)"
      :from="true"
      :adobe-tag="(selectedHelpCenter && selectedHelpCenter.helpCenterName) || ''"
    />
    <CatPopularLinksComponent
      v-if="(props.catPopularLinks && props.catPopularLinks.length > 0) && !Object.keys(selectedCategoryDetails).length"
      :selected-help-center="selectedHelpCenter"
    />
    <CategoriesCards
      :selectedCategoryDetails="selectedCategoryDetails"
      :selectedHelpCenter="selectedHelpCenter"
      :hcSections="hcSections"
    />
    <HelpErrorPage v-if="hcSections && (hcSections.length === 0)" />
  </div>

  <CategoryDetails
    :selectedCategoryDetails="selectedCategoryDetails"
    :selectedHelpCenter="selectedHelpCenter"
    :hcSections="hcSections"
  />
</template>
