<script setup lang="ts">
import {
  inject, onMounted, type Ref, ref,
} from 'vue';
import {
  CdrCard, CdrContainer, CdrText,
} from '@rei/cedar';

import { camelCase } from '../../../common/utils';
import { sendClickAnalytics } from '../../../utils';
import tags from '../../../utils/globalTags';
import { type IHCHomeComponent, IHCHomeComponentKeys } from '../models/interfaces/HcHomeComponent';
import { useIcons } from '../../../common/composables/useIcons';
import type { HelpCenterNamesModel } from '../../../api/types/HelpCenterNamesModel';

const store: any = inject('store');
const props: IHCHomeComponent = store.getValues(IHCHomeComponentKeys);

const mainArray: Ref<HelpCenterNamesModel[]> = ref(props.helpCenterNames || []);

onMounted(() => {
  const partial: any[] = [];
  while (mainArray.value.length) {
    partial.push(mainArray.value.splice(0, 3));
  }
  mainArray.value = partial.reduce((acc, cur) => [...acc, ...cur], []);
});

const helpCardsMetricHandler = async (pages: { adobeName?: string; helpCenterName?: string; }) => {
  const clickData = {
    pageName: '',
    linkName: `${tags.HELP_CENTER_HOMEPAGE}_${pages.adobeName}`,
  };
  sendClickAnalytics(clickData);
  window.location.href = `/help/categories?hc=${camelCase(pages.helpCenterName)}`;
};
</script>

<template>
  <section
    id="help-center-main-cards"
    class="help-center-main-cards"
    style="display: none"
  >
    <div
      id="help-center-cards"
      class="help-center-cards"
    >
      <CdrContainer class="help-center__heading">
        <CdrText
          tag="h2"
          tabindex="0"
          class="help-center__heading-cards"
        >
          Explore all help topics
        </CdrText>
      </CdrContainer>
      <div class="help-center-cards-wrapper">
        <div
          v-for="(pages, i) in mainArray"
          :key="i"
          class="help-center-card-wrapper"
        >
          <CdrCard
            class="help-center-main-card"
            :data-ui="pages.helpCenterName"
            tag="button"
            tabindex="0"
            @click="helpCardsMetricHandler(pages)"
          >
            <div class="help-center-icon-div">
              <component
                :is="useIcons(pages.helpCenterIcon)"
                class="help-center-icon-div-icon"
              />
            </div>
            <div>
              <CdrText
                tag="h3"
                class="help-center-card-header"
              >
                {{ pages.helpCenterName }}
              </CdrText>
              <CdrText
                tag="p"
                class="help-center-card-caption"
              >
                {{ pages.helpCenterDetails }}
              </CdrText>
            </div>
          </CdrCard>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  @import 'src/main/style/helpCenter.scss';
</style>
