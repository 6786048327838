<script setup lang="ts">
import {
  defineProps,
} from 'vue';
import {
  CdrText, CdrLink,
} from '@rei/cedar';
import { sendClickAnalytics } from '../../../utils';
import { snakeCase, camelCaseWithoutSC } from '../../../common/utils';
import { PopularTopicsSection } from '../../../api/types/PopularTopicsSection';

defineProps<{
  popularTopicsSection?: PopularTopicsSection;
}>();

const linkHandler = (saleName?: string, linkName?: string) => {
  const tag = saleName
    ? `rei:Help_Center_${snakeCase(saleName)}_Help_${camelCaseWithoutSC(linkName)}`
    : `rei:Help_Center_Homepage_Popular_${linkName}`;
  analyticsHandler(tag);
};

const analyticsHandler = (source: string) => {
  const clickData = {
    pageName: '',
    linkName: source || 'rei:Help_Center',
  };
  sendClickAnalytics(clickData);
};
</script>

<template>
  <CdrText
    tag="h2"
    tabindex="0"
    class="help-center__heading-links"
  >
    {{ popularTopicsSection?.sectionHeading }}
  </CdrText>
  <article
    class="help-center__card-links-container"
  >
    <div
      v-for="popularTopicsCard in popularTopicsSection?.cardModelList"
      :key="popularTopicsCard.key"
      class="help-center__card-links-card"
    >
      <cdr-text
        tag="h3"
        tabindex="0"
        class="help-center__card-title"
      >
        {{ popularTopicsCard.cardHeading }}
      </cdr-text>
      <CdrLink
        v-for="popularTopicsLink in popularTopicsCard.urlModelList"
        class="help-center__card-links-text"
        :key="popularTopicsLink.key"
        :href="popularTopicsLink.url"
        @click="linkHandler(popularTopicsSection?.key, popularTopicsLink.urlText)"
      >
        {{ popularTopicsLink.urlText }}
      </CdrLink>
    </div>
  </article>
</template>

<style scoped></style>
