<script setup lang="ts">
import {
  onMounted, ref, watch, inject,
} from 'vue';
import { CdrContainer } from '@rei/cedar';

import QuickTools from '../../../components/quickTools/QuickTools.vue';
import CardLinkList from './CardLinkList.vue';
import OrderDetails from '../../../components/orders/OrderDetails.vue';

import { CardLinksKeys, type CardLinksProps } from '../models/interfaces/CardLinksComponent';
import { useUserAccountData } from '../../../common/composables/useUserAccountData';

const store: any = inject('store');
const props: CardLinksProps = store.getValues(CardLinksKeys);

const showQT = ref(true);
const showOD = ref(props.showOrderDetails);
const userInfo = ref({
  isLoggedIn: false,
  firstName: '',
  lastName: '',
});

const checkQuickToolsState = () => {
  const { showOnArticlePage, showOnCategoryPage } = props.showQuickToolsOnPage;
  if (props.selectedCategory) {
    showQT.value = showOnArticlePage;
    return;
  }
  showQT.value = showOnCategoryPage;
};

onMounted(async () => {
  userInfo.value = await useUserAccountData();
  checkQuickToolsState();
});

watch(() => userInfo.value, (newValue) => {
  const newState = { ...newValue };
  if (newState?.isLoggedIn) {
    showOD.value = props.showOrderDetails;
  } else {
    showQT.value = props.showQuickToolsOnPage.showOnCategoryPage;
  }
}, { immediate: true });

const checkOrderLength = (orders: string | any[]) => {
  showQT.value = orders && orders.length <= 0 as any;
  showOD.value = (orders && orders.length > 0) ? props.showOrderDetails : false;
};
</script>

<template>
  <div
    id="help-center-main-card-links"
    class="help-center-main-card-links"
    style="display: none"
  >
    <OrderDetails
      v-if="showOD && userInfo?.isLoggedIn"
      :user-info="userInfo"
      :check-order-length="(orders: string | any[]) => checkOrderLength(orders)"
    />
    <CdrContainer
      tag="section"
      class="help-center__card-section"
    >
      <div class="help-center__card-links">
        <QuickTools
          v-if="showQT"
        />
        <div
          class="help-center__card-links-sales"
          v-for="popularTopicsSection in props.popularTopicsSectionList"
          :key="popularTopicsSection.key"
        >
          <CardLinkList
            :popular-topics-section="popularTopicsSection"
          />
        </div>
      </div>
    </CdrContainer>
  </div>
</template>

<style scoped></style>
