import type { CallCenterModel } from "../../../../api/interfaces/callCenterModel";

export type ContactUsSingle = Pick<
  CallCenterModel,
  | 'retailStoreHoursEvents'
  | 'emailResponseTime'
  | 'helpCenterNumbers'
  | 'popularTopicsLinks'
>;

export const ContactUsSingleKeys: (keyof ContactUsSingle)[] = [
  'retailStoreHoursEvents',
  'emailResponseTime',
  'helpCenterNumbers',
  'popularTopicsLinks',
];
