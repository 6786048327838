import type { CallCenterModel } from "../../../../api/interfaces/callCenterModel";

export type CardLinksProps = Pick<
  CallCenterModel,
  | 'statusForOrdersNotShown'
  | 'showOrderDetails'
  | 'quickTools'
  | 'showPreviousOrderDays'
  | 'showQuickToolsOnPage'
  | 'maxNoOfOrders'
  | 'reiSalesConfig'
  | 'selectedCategory'
  | 'popularTopicsSectionList'
>;

export const CardLinksKeys: (keyof CardLinksProps)[] = [
  'statusForOrdersNotShown',
  'showOrderDetails',
  'quickTools',
  'showPreviousOrderDays',
  'showQuickToolsOnPage',
  'maxNoOfOrders',
  'reiSalesConfig',
  'selectedCategory',
  'popularTopicsSectionList',
];
