<script setup lang="ts">
import {
  inject, onMounted, Ref, ref,
} from 'vue';
import {
  CdrBanner,
  CdrContainer,
  CdrLink,
  CdrList,
  CdrText,
  IconInformationFill,
} from '@rei/cedar';

import LeftHandNavigation from '../../../components/gladlySearchBar/LeftHandNavigation.vue';

import { ICategoriesComponent, ICategoriesComponentKeys } from '../models/interfaces/CategoriesComponent';
import {
  camelCase, snakeCase, replaceCharsFunction,
} from '../../../common/utils';
import { sendClickAnalytics } from '../../../utils';
import tags from '../../../utils/globalTags';

const store: any = inject('store');
const props: ICategoriesComponent = store.getValues(ICategoriesComponentKeys);
const localProps = defineProps<{
  selectedCategoryDetails: any,
  selectedHelpCenter: any,
  hcSections: any
}>();

const showSkeleton: Ref<boolean> = ref(!localProps.hcSections.length);

const getArticlesHref = (article: { name: string; id: number; }) => {
  const { helpCenterName } = localProps.selectedHelpCenter;
  const { header } = localProps.selectedCategoryDetails;
  const { name, id } = article;
  const articleKey = Object.keys(props.articlesRedirect).find((key) => {
    const splitKeys = key.split('|');
    if (splitKeys[0] === helpCenterName
        && ((splitKeys[1] === header) || header.includes('All help content'))
        && splitKeys[2] === name) {
      return key;
    }
    return null;
  });
  const checkForArticleRedirect = articleKey && props.articlesRedirect[articleKey];
  return checkForArticleRedirect || `/help?a=${replaceCharsFunction(name)}---id--${id}`;
};

const articlesMetricHandler = (article: { name: any; }) => {
  try {
    const { adobeName, mainHelpCenter, helpCenterName } = localProps.selectedHelpCenter;
    const { header } = localProps.selectedCategoryDetails;
    const { name } = article;
    let linkName = '';
    if (props.hasSecondaryHC) {
      linkName = `${snakeCase(mainHelpCenter)}_${camelCase(helpCenterName)}_${camelCase(header)}`;
    } else {
      linkName = `${adobeName}_${camelCase(header)}`;
    }
    const clickData = {
      pageName: '',
      linkName: `${tags.HELP_CENTER_UPPERCASE}_${linkName}_${camelCase(name)}`,
    };
    sendClickAnalytics(clickData);
  } catch (err) {
    // Left on purpose to catch
    // the unexpected error
    console.warn('error', err);
  }
};

onMounted(() => { showSkeleton.value = false; });
</script>

<template>
  <CdrContainer
    v-if="Object.keys(localProps.selectedCategoryDetails).length"
    id="help-center-articles"
    class="help-center-articles"
  >
    <div class="help-center-articles-nav-main">
      <div
        v-if="!showSkeleton"
        class="help-center-articles-main-articles"
      >
        <CdrText
          tag="h1"
          tabindex="0"
          class="help-center-articles-heading"
          data-ui="help-center-articles-heading"
        >
          {{ selectedCategoryDetails && selectedCategoryDetails.header }}
        </CdrText>
        <CdrList
          class="help-center-articles-ul"
          modifier="unordered"
        >
          <li
            v-for="(answer, i) in selectedCategoryDetails.answers"
            :key="i"
          >
            <CdrLink
              :href="getArticlesHref(answer)"
              modifier="standalone"
              class="help-center-articles-link"
              @click="articlesMetricHandler(answer)"
            >
              {{ answer.name }}
            </CdrLink>
          </li>
        </CdrList>
        <CdrBanner
          v-if="selectedCategoryDetails && selectedCategoryDetails.answers
            && selectedCategoryDetails.answers.length === 0"
          class="help-center-articles-banner"
          tabindex="0"
          type="info"
        >
          <template #icon-left>
            <icon-information-fill />
          </template>
          <CdrText
            tag="p"
            class="help-center-articles-banner-text"
            data-ui="rei-outlet-msg"
          >
            Currently, no Articles found for the selected category. Please try again by refreshing the page or use our search feature. You can also contact
            us for help.
          </CdrText>
        </CdrBanner>
      </div>
      <LeftHandNavigation
        v-if="props.showLeftHandNavigation && props.selectedCategory"
        navigation-class="help-center-left-navigation-article"
        from-nav="Help"
      />
    </div>
  </CdrContainer>
</template>
