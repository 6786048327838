<script setup lang="ts">
import { inject } from 'vue';
import {
  CdrButton, CdrText, CdrLink,
} from '@rei/cedar';
import {
  ICategoriesComponent, ICategoriesComponentKeys,
} from '../models/interfaces/CategoriesComponent';
import {
  camelCase, replaceCharsFunction, snakeCase,
} from '../../../common/utils';
import { sendClickAnalytics } from '../../../utils';
import tags from '../../../utils/globalTags';

const store: any = inject('store');
const props: ICategoriesComponent = store.getValues(ICategoriesComponentKeys);
const localProps = defineProps<{
  selectedCategoryDetails: any,
  selectedHelpCenter: any,
  hcSections: any
}>();
const { hcSections } = localProps;

const getHcSectionsHeaders = () => {
  const {
    helpCenterCDNResponse: {
      sections,
    },
  } = props;
  return sections.map((section: { header: any; }) => (section.header));
};
const hcSectionsHeaders = getHcSectionsHeaders();

const checkForCategoriesClass = (i: number) => {
  if (i === hcSectionsHeaders.length - 1) {
    return 'hide';
  }
  return '';
};

const redirectForOneArticle = (category: string | number) => {
  let selectedCat: any = hcSections?.length && hcSections.map((section: any[]) => section.find((hc) => camelCase(hc.header) === camelCase(category)));
  selectedCat = selectedCat?.find((cat: any) => !!cat);
  const answerIds = selectedCat?.answerIds;
  return (answerIds && (answerIds.length === 1))
    ? `/help?a=${replaceCharsFunction((selectedCat?.header) || '')}---id--${answerIds[0]}` : null;
};

const categoriesMetricHandler = (category: string | number) => {
  const { adobeName, helpCenterName, mainHelpCenter } = localProps.selectedHelpCenter;
  let linkName = '';
  if (props.hasSecondaryHC) {
    linkName = `${snakeCase(mainHelpCenter)}_${camelCase(helpCenterName)}`;
  } else {
    linkName = adobeName;
  }
  const clickData = {
    pageName: '',
    linkName: `${tags.HELP_CENTER_UPPERCASE}_${linkName}_${camelCase(category)}`,
  };
  sendClickAnalytics(clickData);
  const checkForDirectRedirectURL = props.gladlyArticlesRedirect[category];
  const checkForOneArticle = redirectForOneArticle(category);
  const articleURL = props.hasSecondaryHC
    ? `/help/articles?hc=${mainHelpCenter}&secHc=${camelCase(helpCenterName)}&ctgry=${camelCase(category)}`
    : `/help/articles?hc=${camelCase(helpCenterName)}&ctgry=${camelCase(category)}`;
  window.location.href = checkForDirectRedirectURL
      || checkForOneArticle
      || articleURL;
};
</script>

<template>
  <div
    v-if="!Object.keys(selectedCategoryDetails).length && (hcSections && hcSections.length > 0)"
    id="help-center-category-section"
    class="help-center-category-section"
  >
    <CdrText
      tag="h2"
      tabindex="0"
      class="help-center-category-section-heading"
    >
      All Topics
    </CdrText>
    <div class="help-center-category-wrapper">
      <div
        v-for="(header, i) in hcSectionsHeaders"
        :key="i"
        class="help-center-category-row"
      >
        <div
          :key="i"
          :class="['help-center-category-column', checkForCategoriesClass(i)]"
          :data-ui="header"
        >
          <CdrButton
            class="help-center-category-button"
            modifier="secondary"
            @click="categoriesMetricHandler(header)"
          >
            {{ header }}
          </CdrButton>
        </div>
      </div>
    </div>
    <cdr-link
      modifier="standalone"
      class="help-center-category-seeAll-link"
      @click="categoriesMetricHandler('See all')"
    >
      See all in {{ (selectedHelpCenter && selectedHelpCenter.helpCenterName.toLowerCase()) || '' }}
    </cdr-link>
  </div>
</template>
