import { createSSRApp } from 'vue';
import App from './helpCenterHome.vue';
import { useDataStore } from '../../store/useDataStore';

export default function createApp(props) {
  const app = createSSRApp(App);
  const store = useDataStore(props);
  app.provide('store', store);
  return { app, store };
}
