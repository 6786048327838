import type { CallCenterModel } from "../../../../api/interfaces/callCenterModel"

export type ICategoriesComponent = Pick<
  CallCenterModel,
  | 'helpCenterName'
  | 'helpCenterNames'
  | 'selectedCategory'
  | 'gladlyArticlesRedirect'
  | 'helpCenterCDNResponse'
  | 'articlesRedirect'
  | 'showLeftHandNavigation'
  | 'showQuickToolsOnPage'
  | 'quickTools'
  | 'catPopularLinks'
  | 'maxCatPopularLinks'
  | 'gladlyNewHelpCenters'
  | 'hasSecondaryHC'
>;

export const ICategoriesComponentKeys: (keyof ICategoriesComponent)[] = [
  'helpCenterName',
  'helpCenterNames',
  'selectedCategory',
  'gladlyArticlesRedirect',
  'helpCenterCDNResponse',
  'articlesRedirect',
  'showLeftHandNavigation',
  'showQuickToolsOnPage',
  'quickTools',
  'catPopularLinks',
  'maxCatPopularLinks',
  'gladlyNewHelpCenters',
  'hasSecondaryHC',
];